import api from '../api'

const API = '/api/employee/paychecks'

export const EmployeePaycheck = {
  get: function(params) {
    return api.get(API , params)
  },
  downloadPaystub: function(id, params) {
    return api.get( `${API}/${id}/download-paystub`, params)
  },
}
