<template>
  <div class="row">
    <div class="col-md-12">
      <h4>My Paychecks</h4>
    </div>
    <div class="col-md-3" v-for="paycheck in paychecks" :key="paycheck.id">
      <el-card>
        <div>Check Amount</div>
        <div style="font-size: 50px;">{{ money(paycheck.check_amount) }}</div>
        <hr>
        <div>Check Date: {{ paycheck.payroll.check_date }}</div>
        <div>Payroll Date: {{ paycheck.payroll.pay_start_date }} to {{ paycheck.payroll.pay_end_date }}</div>
        <div>Approved Date: {{ paycheck.payroll.approve_date }}</div>
        <div>
          <el-button class="btn-block" type="primary" size="mini" @click="downloadPaystub(paycheck)">DOWNLOAD PAYSTUB</el-button>
        </div>
      </el-card>
    </div>
    <div class="col-md-12 row">
          <div class="col-sm-6 pagination-info mt-2">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
            </p-pagination>
          </div>
        </div>
  </div>
</template>
<script>

import { EmployeePaycheck } from '@/resources/employee/employee_paychecks'

export default {
  data() {
    return {
      paychecks: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPaychecks()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPaychecks()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPaychecks()
    }
  },
  created: function () {
    this.getPaychecks()
  },
  methods: {
    downloadPaystub(paycheck) {
      this.$loading = true
      EmployeePaycheck.downloadPaystub(paycheck.id)
        .then(result => {
          const link = document.createElement('a');
          link.setAttribute('href', result.data.paystub.link); // Replace 'path_to_your_file' with the file URL or data
          link.setAttribute('download', result.data.paystub.filename); // Replace 'filename.ext' with the desired file name and extension

          // Simulate click event
          link.click();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPaychecks() {
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      this.paychecks = []
      EmployeePaycheck.get({ params: params })
        .then(result => {
          if (result.data.paychecks)
            this.paychecks = result.data.paychecks
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch paychecks',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
